import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MyImg from "../assets/img/myImg.png"
import { ArrowRightCircle } from 'react-bootstrap-icons';
import TrackVisibility from 'react-on-screen';
import Kushal_CV from "../assets/img/kushal.pdf"

export const Banner = () => {
    // Function will execute on click of button

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random());
  const [index, setIndex] = useState(1);
  const toRotate = [ "Information Systems Auditor", "Governance, Risk, and Compliance Analyst" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 3);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
      
        <Row className="aligh-items-center">
        <Col xs={8} md={7} xl={3}>
          
            <TrackVisibility>
                <div>
                  <img src={MyImg} alt="Header Img"/>
                </div>
        
              
            </TrackVisibility>
          </Col>
          <Col xs={8} md={6} xl={7}>
            <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">My Portfolio</span>
                <h1>{`Kushal Kunwar`}</h1>
                <h2><br/> <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Information Systems Auditor", "Governance, Risk, and Compliance Analyst" ]'><span className="wrap">{text}</span></span></h2>
                  <p>With a Spark of Curiosity and a Dash of Joy, Venturing into the Hidden Corners of Technology, Excited to Unravel Its Secrets and Wonders.</p>
                    <a href={Kushal_CV} download="Kushal_CV" ><button >Download CV<ArrowRightCircle size={25} /></button></a>
                  
              </div>}
            </TrackVisibility>

          </Col>
        </Row>
      </Container>
    </section>
  )
}
