import { Col } from "react-bootstrap";

export const MyJourneyCard = ({ title,company, description, date }) => {
  return (
      <Col xs={12} md={6}> 
        <li className="journey">
          <h5>{date}</h5>
          <h4>{title}</h4>
          <h4>{company}</h4>
          <span>{description}</span>
        </li>
    </Col>
  )
}
