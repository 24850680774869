
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ubuntu from "../assets/img/ubuntu.svg";
import css from "../assets/img/css-icon.svg";
import html from "../assets/img/html-icon.svg";
import js from "../assets/img/javascript.svg";
import kali from "../assets/img/kali-linux.svg";
import nmap from "../assets/img/nmap_icon.svg";
import python from "../assets/img/python.svg";
import react from "../assets/img/react-js-icon.svg";
import vmware from "../assets/img/vmware-logo.svg";
import vsc from "../assets/img/vsc.svg";
import wireshark from "../assets/img/wireshark.svg"
import vbox from "../assets/img/virtualbox.png"
import burp from "../assets/img/burpsuite.png"
import colorSharp from "../assets/img/color-sharp.png"
import windows from "../assets/img/windows.png"

const OperatingSystems = () => {
    return (
        <Carousel
            responsive={{ desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 }, tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
                mobile: { breakpoint: { max: 464, min: 0 }, items: 1 } }}
            infinite={true}
            className="owl-carousel owl-theme skill-slider"
        >
            <div className="item">
                <img src={windows} alt="Image" />
                <h5>Windows</h5>
            </div>
            <div className="item">
                <img src={ubuntu} alt="Image" />
                <h5>Ubuntu</h5>
            </div>
            <div className="item">
                <img src={kali} alt="Image" />
                <h5>Kali</h5>
            </div>
            
        </Carousel>
    );
};

const Tools = () => {
    return (
        <Carousel
            responsive={{ desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 }, tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
                mobile: { breakpoint: { max: 464, min: 0 }, items: 1 } }}
            infinite={true}
            className="owl-carousel owl-theme skill-slider"
        >
            <div className="item">
                <img src={nmap} alt="Image" />
                <h5>NMAP</h5>
            </div>
            <div className="item">
                <img src={vmware} alt="Image" />
                <h5>Vmware</h5>
            </div>
            <div className="item">
                <img src={wireshark} alt="Image" />
                <h5>Wireshark</h5>
            </div>
            <div className="item">
                <img src={vsc} alt="Image" />
                <h5>VSCode</h5>
            </div>
            <div className="item">
                <img src={vbox} alt="Image" />
                <h5>Virtual Box</h5>
            </div>
            <div className="item">
                <img src={burp} alt="Image" />
                <h5>BurpSuite</h5>
            </div>
        </Carousel>
    );
};

const ProgrammingLanguages = () => {
    return (
        <Carousel
            responsive={{ desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 }, tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
                mobile: { breakpoint: { max: 464, min: 0 }, items: 1 } }}
            infinite={true}
            className="owl-carousel owl-theme skill-slider"
        >
            <div className="item">
                <img src={python} alt="Image" />
                <h5>Python</h5>
            </div>
            <div className="item">
                <img src={html} alt="Image" />
                <h5>HTML</h5>
            </div>
            <div className="item">
                <img src={css} alt="Image" />
                <h5>CSS</h5>
            </div>
            
            <div className="item">
                <img src={js} alt="Image" />
                <h5>Javascript</h5>
            </div>
            <div className="item">
                <img src={react} alt="Image" />
                <h5>React JS</h5>
            </div>

        </Carousel>
    );
};

/*const KnownFrameworks = () => {
  return (
      <Carousel
          responsive={{ tablet: { breakpoint: { max: 3000, min: 1024 }, items: 3 } }}
          infinite={true}
          className="owl-carousel owl-theme skill-slider"
      >
          <div className="item">
              <img src={html} alt="Image" />
              <h5>HTML</h5>
          </div>
          <div className="item">
              <img src={css} alt="Image" />
              <h5>CSS</h5>
          </div>
          <div className="item">
              <img src={python} alt="Image" />
              <h5>Python</h5>
          </div>
          <div className="item">
              <img src={js} alt="Image" />
              <h5>Javascript</h5>
          </div>
          <div className="item">
              <img src={meter1} alt="Image" />
              <h5>React JS</h5>
          </div>
      </Carousel>
  );
  <div className="knownFrameworks">
                            <br/><br/>
                                <h3>Known Frameworks</h3>
                                <br/>
                                <KnownFrameworks />
                                <br/>
                            </div>
};*/
/*const SoftSkills = () => {
  return (
      <Carousel
          responsive={{ tablet: { breakpoint: { max: 3000, min: 1024 }, items: 3 } }}
          infinite={true}
          className="owl-carousel owl-theme skill-slider"
      >
          <div className="item">
              <img src={html} alt="Image" />
              <h5>HTML</h5>
          </div>
          <div className="item">
              <img src={css} alt="Image" />
              <h5>CSS</h5>
          </div>
          <div className="item">
              <img src={python} alt="Image" />
              <h5>Python</h5>
          </div>
          <div className="item">
              <img src={js} alt="Image" />
              <h5>Javascript</h5>
          </div>
          <div className="item">
              <img src={meter1} alt="Image" />
              <h5>React JS</h5>
          </div>
      </Carousel>
  );
  <div className="softSkills">
                            <br/><br/>
                                <h3>Soft Frameworks</h3>
                                <br/>
                                <softSkills />
                                <br/>
                            </div>
};*/


export const Skills = () => {
    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx wow zoomIn">
                            <h2>Skills</h2>
                            <div className="os-skills">
                                <h3>Operating Systems</h3>
                                <br/>
                                <OperatingSystems />
                                <br/><br/><br/>        
                            </div>
                            <div className="tools-skills">
                                <h3>Tools</h3>
                                <br/>
                                <Tools />
                                <br/>
                            </div>
                            <div className="programming-skills">
                            <br/><br/>
                                <h3>Programming and Other Languages</h3>
                                <br/>
                                <ProgrammingLanguages />
                                <br/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image" src={colorSharp} alt="Image" />
        </section>
    );
};
