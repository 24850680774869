
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp2.png"

export const Education = () => {
  const events= [
      {
        date: "2018 - 2020",
        description: "School Leaving Certificate Science Faculty",
        college: "Prasadi Academy",
      },

      {
        date: "2021 - 2023",
        description: "BSc IT (Hons) Networking and IT Security",
        college: "Islington College Pvt, Ltd.",
      },
      {
        date: "2023 - Present",
        description: "Master's Degree in Information Technology - Cyber Secuity",
        college: "Islington College Pvt, Ltd.",
      }
    ];

  return (
    <section className="education" id="education">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="education-bx">
                        <h2>Education Timeline</h2>
                        <ul>
                            {events.map((event, index) => (
                            <li key={index}>
                                <div className="date">{event.date}</div>
                                <div className="description">{event.description}</div>
                                <div className="description">{event.college}</div>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-right" src={colorSharp} alt="Image" />
    </section>
    
  )
  
}
