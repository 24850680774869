import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import { MyJourneyCard } from "./MyJourneyCard";
import TrackVisibility from 'react-on-screen';

export const MyJourney = () => {

  const experience = [
    {
      title: "IS Auditor and GRC Analyst",
      company: "Vairav Technology Security Pvt. Ltd.",
      description: "Information Systems Auditing and Goverance, Risk and Compliance Analyst",
      date: "May 2023- Present"
    },
    {
      title: "IT and NOC Intern",
      company: "Vairav Technology Security Pvt. Ltd.",
      description: "Information Technology and Network Operations Center Intern",
      date: "November 2022- January 2023"
    },
    
  ];
  const certifications = [
    {
      title: "PentesterLab's Essential Badge",
      company: "Pentester Lab",
      description: "#PTLE5530",
      date: "2022-06-18"
    },
    {
      title: "PentesterLab's HTTP Badge",
      company: "Pentester Lab",
      description: "#PTLH0444",
      date: "2022-06-19"
    },
    {
      title: "PentesterLab's Unix Badge",
      company: "Pentester Lab",
      description: "#PTLU9691",
      date: "2022-03-29"
    },
    {
      title: "Complete Beginner Learning Path",
      company: "Try Hack Me",
      description: "THM-LOAY3CMKYF",
      date: "2022-02-03"
    },
    {
      title: "Jr Penetration Tester Learning Path",
      company: "Try Hack Me",
      description: "THM-WUWRJS3AHP",
      date: "2022-02-26"
    },
    {
      title: "Pre-Security Learning Path",
      company: "Try Hack Me",
      description: "THM-X5RJFD8DCU",
      date: "2022-01-23"
    },
    
  ];

  return (
    <section className="myJourney" id="myJourney">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <br/>
                <Tab.Container id="myJourney-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Experience</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Certifications</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        <Row>
                          {
                            experience.map((experience, index) => {
                              return (
                                <MyJourneyCard
                                  key={index}
                                  {...experience}
                                />
                              )
                            })
                          }
                        </Row>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        <Row>
                          {
                            certifications.map((certifications, index) => {
                              return (
                                <MyJourneyCard
                                  key={index}
                                  {...certifications}
                                />
                              )
                            })
                          }
                        </Row>
                      </Row>
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      
    </section>
  )
}
