import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Education } from "./components/Education";
import { MyJourney } from "./components/MyJourney";
import { Skills } from "./components/Skills";
import { Footer } from "./components/Footer";




function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner/>
      <Education/>
      <MyJourney/>
      <Skills />
      
      <Footer />
      
    </div>
  );
}

export default App;
