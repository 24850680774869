import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
      
        <Row className="align-items-center">
          <p>Copyright 2024. All Rights Reserved</p>
        </Row>
      </Container>
    </footer>
  )
}
